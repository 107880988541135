import * as React from 'react'





// 11:30  =>   0px
// dann pixel pro minute


const minZeit = '11:30'
const maxZeit = '3:00'

const pixelPerMinute = 3.0



const calcMinuten = zeit => {
    let stunden = parseInt(zeit.split(':')[0])
    let minuten = parseInt(zeit.split(':')[1])
    if(stunden < 5) {
        stunden += 24
    }
    return (stunden*60)+minuten
}



const calcPosition = zeit => {
    return (calcMinuten(zeit) - calcMinuten(minZeit)) * pixelPerMinute
}


const height = calcPosition(maxZeit)






export default function ContentView({tag,bands}) {





    

    const renderBands = (tag,stage) => {
        let ret = []
        for(let band of bands) {
            if(band.tag === tag && band.stage === stage) {
                ret.push(
                    <div
                        className="band"
                        style={{
                            top: calcPosition(band.von),
                            height: (calcPosition(band.bis)-calcPosition(band.von))
                        }}
                    >
                        <span style={{position: 'absolute', left: '12px', top: '12px'}}>
                            {band.link != null ? 
                                <a target='_blank' href={band.link}>{band.label}</a>
                            :
                                band.label
                            }
                        </span>
                        <span style={{position: 'absolute', right: '12px', top: '12px'}}>
                            {band.von} - {band.bis}
                        </span>
                        <span style={{position: 'absolute', left: '12px', bottom: '12px'}}>
                            {band.genre}
                        </span>
                        <span style={{position: 'absolute', right: '12px', bottom: '12px'}}>
                            {band.bewertung}
                        </span>
                    </div>
                )
            }

        }
        return ret
    }










    return (

        <div className="content-view">


            <div className="stage">
                <div className="label">MAIN STAGE</div>
                <div className="content" style={{height: height}}>{renderBands(tag,'MAIN STAGE')}</div>
            </div>

            <div className="stage">
                <div className="label">T-STAGE</div>
                <div className="content" style={{height: height}}>{renderBands(tag,'T-STAGE')}</div>
            </div>

            <div className="stage">
                <div className="label">CAMEL STAGE</div>
                <div className="content" style={{height: height}}>{renderBands(tag,'CAMEL STAGE')}</div>
            </div>

            <div className="stage">
                <div className="label">CAMPSITE CIRCUS</div>
                <div className="content" style={{height: height}}>{renderBands(tag,'CAMPSITE CIRCUS')}</div>
            </div>
        </div>



    )


    
}


















