import * as React from 'react'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import ContentView from './ContentView'

import './App.css'




const tage = [
    // {id: 'DI', label: 'Di, 16.08.'},
    {id: 'MI', label: 'Mi, 14.08.'},
    {id: 'DO', label: 'Do, 15.08.'},
    {id: 'FR', label: 'Fr, 16.08.'},
    {id: 'SA', label: 'Sa, 17.08.'}
]



const bands = require('./running-order-2024.json')



function App() {


    const [tag, setTag] = React.useState('DI');




    const handleTabChange = (event, value) => {
        console.log(value)
        setTag(value)
    }


    return (



        <div className="App">
            <div className="tab-menu">
                <Tabs value={tag} onChange={handleTabChange}>
                    { tage.map(tag => 
                        <Tab value={tag.id} label={tag.label} />
                    )}
                </Tabs>
            </div>
            <ContentView
                tag={tag}
                bands={bands}
            />
        </div>

    )
}






// function TabPanel(props) {
//     const { children, value, index, ...other } = props;

//     return (
//         <div
//             role="tabpanel"
//             hidden={value !== index}
//             id={`simple-tabpanel-${index}`}
//             aria-labelledby={`simple-tab-${index}`}
//             {...other}
//         >
//             {value === index && (
//                 <Box sx={{ p: 3 }}>
//                     <Typography>{children}</Typography>
//                 </Box>
//             )}
//         </div>
//     );
// }

// TabPanel.propTypes = {
//     children: PropTypes.node,
//     index: PropTypes.number.isRequired,
//     value: PropTypes.number.isRequired,
// };

// function a11yProps(index) {
//     return {
//         id: `simple-tab-${index}`,
//         'aria-controls': `simple-tabpanel-${index}`,
//     };
// }

// export default function BasicTabs() {
//     const [value, setValue] = React.useState(0);

//     const handleChange = (event, newValue) => {
//         setValue(newValue);
//     };

//     return (
//         <Box sx={{ width: '100%' }}>
//             <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
//             </Box>
//             <TabPanel value={value} index={0}>
//                 Item One
//             </TabPanel>
//             <TabPanel value={value} index={1}>
//                 Item Two
//             </TabPanel>
//             <TabPanel value={value} index={2}>
//                 Item Three
//             </TabPanel>
//         </Box>
//     );
// }



export default App
